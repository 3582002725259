import { getPathFromNode } from './pathHelper';
import { getActualPathFromLocation } from './pathHelper';
import { createPathFromArray } from './pathHelper';

export const getNodeFromEdges = (edges, path) => {
  let nodeFound = null;

  if (edges && edges.length > 0) {
    edges.forEach(({ node }) => {
      const nodePath = getPathFromNode(node);
      if (nodePath === path) nodeFound = node;
    });
  }
  return nodeFound;
};

export const getMenuSectionFromNode = (node) => {
  let menuSection = { level: 'site', selected: null };

  if (node && node.fields) {
    let nodePath = node.fields.path;

    if (nodePath) {
      // remove double and trailing slash
      nodePath = nodePath.replace('//', '/');
      if (nodePath !== '/') {
        nodePath = nodePath.endsWith('/') ? nodePath.slice(0, -1) : nodePath;
      }

      const nodePathParts = nodePath.split('/');
      const nodePathLength = nodePathParts ? nodePathParts.length : 0;

      const language = nodePathParts[1];
      const area = nodePathParts[2];
      const book = nodePathParts[3];

      if (nodePathLength === 2 && language !== '') {
        menuSection = { level: 'language', selected: language };
      }
      if (nodePathLength === 3 && area !== '') {
        menuSection = { level: 'area', selected: area };
      }
      if (nodePathLength >= 4 && book !== '') {
        menuSection = { level: 'book', selected: book };
      }
      // console.log('Get MenuSection');
      // console.log(`nodePath: ${nodePath}`);
    }
  }
  //console.log(menuSection);
  return menuSection;
};

export const getRouteParamsFromNode = (node) => {
  let routeParams = {};

  if (node && node.fields) {
    let nodePath = node.fields.path;
    let nodeSlug = node.fields.slug;
    let isIndexRoute = false;

    if (nodePath && nodeSlug) {
      // remove double and trailing slash
      nodePath = nodePath.replace('//', '/');
      if (nodePath !== '/') {
        nodePath = nodePath.endsWith('/') ? nodePath.slice(0, -1) : nodePath;
      }
      nodeSlug = nodeSlug.replace('//', '/');
      if (nodeSlug !== '/') {
        nodeSlug = nodeSlug.endsWith('/') ? nodeSlug.slice(0, -1) : nodeSlug;
      }

      if (nodePath === nodeSlug) isIndexRoute = true;

      // get parameter from node path
      const nodePathParts = nodePath.split('/');
      const nodePathLength = nodePathParts ? nodePathParts.length : 0;
      const language = nodePathParts[1];
      const area = nodePathParts[2];
      const book = nodePathParts[3];
      const chapter = nodePathParts[4];

      if (nodePathLength >= 2 && language !== '') {
        routeParams = {
          ...routeParams,
          language: language,
        };
      }
      if (nodePathLength >= 3 && area !== '') {
        routeParams = {
          ...routeParams,
          area: area,
        };
      }
      if (nodePathLength >= 4 && book !== '') {
        routeParams = {
          ...routeParams,
          book: book,
        };
      }
      if (nodePathLength >= 5 && chapter !== '') {
        routeParams = {
          ...routeParams,
          chapter: chapter,
        };
      }

      // get parameter from node slug
      if (isIndexRoute) {
        routeParams = {
          ...routeParams,
          page: 'index',
        };
      } else {
        const nodeSlugParts = nodeSlug.split('/');
        const nodeSlugLength = nodeSlugParts ? nodeSlugParts.length : 0;
        const page = nodeSlugParts[nodeSlugLength - 1];
        routeParams = {
          ...routeParams,
          page: page,
        };
      }
      // console.log('Get RouteParams');
      // console.log(`nodePath: ${nodePath}`);
      // console.log(`nodeSlug: ${nodeSlug}`);
    }
  }
  // console.log(routeParams);
  return routeParams;
};

export const getMenuRootPathFromNode = (node) => {
  let menuRootPath = '';
  let menuRootArray = [];

  const nodeMenuSection = getMenuSectionFromNode(node);
  const nodeRouteParams = getRouteParamsFromNode(node);

  if (nodeMenuSection && nodeRouteParams) {
    if (nodeMenuSection.level === 'language') {
      menuRootArray[0] = nodeRouteParams.language;
    } else if (nodeMenuSection.level === 'area') {
      menuRootArray[0] = nodeRouteParams.language;
      menuRootArray[1] = nodeRouteParams.area;
    } else if (nodeMenuSection.level === 'book') {
      menuRootArray[0] = nodeRouteParams.language;
      menuRootArray[1] = nodeRouteParams.area;
      menuRootArray[2] = nodeRouteParams.book;
    }
    // console.log('MenuRootPath');
    // console.log(menuRootArray);
    menuRootPath = createPathFromArray(menuRootArray);
  }
  // console.log(`menuRootPath: ${menuRootPath}`);
  return menuRootPath;
};

export const getFilteredAndSortedEdges = (edges, location) => {
  const filteredEdges = getFilteredEdges(edges, location);

  filteredEdges.sort((a, b) => {
    return a.node.fields.short - b.node.fields.short;
  });

  return filteredEdges;
};

export const getFilteredEdges = (edges, location) => {
  let filteredEdges = [];

  if (edges && edges.length > 0) {
    // ----------------------
    // Process Definition
    // ----------------------
    // 1. get actualMenuSection from actual path (menu start point)
    // 2. filter the edges
    //    root = site:     - filter: category = site       (type: site, single)
    //                     -         category = language   (type: site, single)
    //
    //    root = language: - filter: category = language   (type: language, single)
    //                     -         category = area       (type: area, single)
    //                     -         language = selected
    //
    //    root = area:     - filter: category = area       (type: area, single)
    //                     -         category = book       (type: doc, blog, ...)
    //                     -         language = selected
    //                     -         area = selected
    //
    //    root = book:     - filter: category = book       (type: doc, blog, ...)
    //                     -         category = chapter    (type: chapter, thread, ...)
    //                     -         category = page       (type: article, post, ...)
    //                     -         language = selected
    //                     -         area = selected
    //                     -         book = selected
    //
    // 3. add menupath to the node (menupath = slug without root path)

    // ---------------------------------------------
    // 1. get actualMenuSection
    // ---------------------------------------------
    const actualPath = getActualPathFromLocation(location);
    const actualNode = getNodeFromEdges(edges, actualPath);
    const actualMenuSection = getMenuSectionFromNode(actualNode);
    const actualRouteParams = getRouteParamsFromNode(actualNode);

    // ---------------------------------------------
    // 2. filter the edges
    // ---------------------------------------------
    // console.log('Filter Edges');
    // console.log(`Menu Level: ${actualMenuSection.level}`);
    // console.log(`actualPath: ${actualPath}`);
    // console.log(actualNode);

    let filteredBaseEdges = [];

    if (edges && edges.length > 0) {
      edges.forEach((edge) => {
        const { node } = edge;
        const nodeRouteParams = getRouteParamsFromNode(node);

        if (actualMenuSection && node && node.fields) {
          if (actualMenuSection.level === 'site') {
            if (node.fields.category === 'site' || node.fields.category === 'language') {
              filteredBaseEdges.push(edge);
            }
          } else if (actualMenuSection.level === 'language') {
            if (node.fields.category === 'language' || node.fields.category === 'area') {
              if (nodeRouteParams.language === actualRouteParams.language) {
                filteredBaseEdges.push(edge);
              }
            }
          } else if (actualMenuSection.level === 'area') {
            if (node.fields.category === 'area' || node.fields.category === 'book') {
              if (
                nodeRouteParams.language === actualRouteParams.language &&
                nodeRouteParams.area === actualRouteParams.area
              ) {
                filteredBaseEdges.push(edge);
              }
            }
          } else if (actualMenuSection.level === 'book') {
            if (
              node.fields.category === 'book' ||
              node.fields.category === 'chapter' ||
              node.fields.category === 'page'
            ) {
              if (
                nodeRouteParams.language === actualRouteParams.language &&
                nodeRouteParams.area === actualRouteParams.area &&
                nodeRouteParams.book === actualRouteParams.book
              ) {
                filteredBaseEdges.push(edge);
              }
            }
          }
        }
      });
    }
    // console.log(filteredBaseEdges);

    // ---------------------------------------------
    // 3. add menupath and isindex to the node
    // ---------------------------------------------
    const actualMenuRootPath = getMenuRootPathFromNode(actualNode);

    filteredBaseEdges.forEach((edge) => {
      const { node } = edge;
      let newNode = { ...node };
      let isIndexRoute = false;

      const nodeSlug = newNode.fields.slug;
      const nodePath = newNode.fields.path;

      if (nodeSlug === nodePath) isIndexRoute = true;

      // remove the menu root path from slug
      let menuPath = newNode.fields.slug;
      if (actualMenuRootPath && actualMenuRootPath.length > 1) {
        if (menuPath.startsWith(actualMenuRootPath)) {
          menuPath = menuPath.replace(actualMenuRootPath, '');
        }
      }
      if (!menuPath.startsWith('/')) menuPath = `/${menuPath}`;
      if (!menuPath) menuPath = '/';
      newNode.menupath = menuPath;
      newNode.isindex = isIndexRoute;

      let newEdge = {
        ...edge,
        node: newNode,
      };
      filteredEdges.push(newEdge);
    });
    // console.log(filteredEdges);
  }
  return filteredEdges;
};
