import React from 'react';
import _ from 'lodash';
import config from '../../../config/config';
import TreeNode from './treeNode';
// import useSettings from '../../hooks/useSettings';
import { getNavigationFromEdges } from '../../helpers/treeHelper';

import { readFromLocalStore } from '../../store/localStore';
import { writeToLocalStore } from '../../store/localStore';

// Workaround
const saveSettings = (settings) => {
  const currentSettings = getSettings();
  const mergedSettings = _.merge({}, currentSettings, settings);
  writeToLocalStore('settings', JSON.stringify(mergedSettings));
};

// Workaround
const getSettings = () => {
  const storedData = readFromLocalStore('settings');
  const settings = storedData ? JSON.parse(storedData) : {};
  return settings;
};

const Tree = ({ edges, location }) => {
  // const { settings, saveSettings } = useSettings();
  const treeData = getNavigationFromEdges(edges, location);
  const settings = getSettings();

  const toggle = (url) => {
    const settings = getSettings();
    const baseCollapsed = settings.menuCollapsed || {};
    const newCollapsed = {
      ...baseCollapsed,
      [url]: !baseCollapsed[url],
    };
    saveSettings({ menuCollapsed: { ...newCollapsed } });
  };

  return (
    <TreeNode
      className={`${config.sidebar.frontLine ? 'showFrontLine' : 'hideFrontLine'} firstLevel`}
      setCollapsed={toggle}
      collapsed={settings.menuCollapsed || {}}
      {...treeData}
    />
  );
};

export default Tree;
