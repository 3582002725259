import * as React from 'react';
import { Link as PortalLink } from 'gatsby';
//import { LocalizedLink as PortalLink } from 'gatsby-theme-i18n';
import isAbsoluteUrl from 'is-absolute-url';

const Link = ({ to, ...props }) =>
  isAbsoluteUrl(to) ? (
    <a href={to} {...props}>
      {props.children}
    </a>
  ) : (
    <PortalLink to={to} {...props}>
      {props.children}
    </PortalLink>
  );

export default Link;
