import config from '../../config/config';
import { getFilteredAndSortedEdges } from './nodeHelper';
import { getNavigationFromEdges } from './treeHelper';

const forcedNavOrder = config.sidebar.forcedNavOrder;

export const getNavItemsFromNavigationTree = (navItems = [], treeList) => {
  if (treeList && treeList.length > 0) {
    treeList.forEach((node) => {
      const navItem = {
        title: node.title,
        url: node.url,
      };
      navItems.push(navItem);
      if (node.items && node.items.length > 0) {
        getNavItemsFromNavigationTree(navItems, node.items);
      }
    });
  }
};

export const getNavLinksFromEdges = (edges, location) => {
  const navTree = getNavigationFromEdges(edges, location);

  // Flatten the navigation tree
  let navLinks = [];
  getNavItemsFromNavigationTree(navLinks, navTree.items);

  return navLinks;
};

// DEPRECATED
export const getNavLinksFromEdgesExt = (edges, location) => {
  const navNodes = getNavNodesFromEdges(edges, location);

  console.log('PreviousNext:');
  console.log(navNodes);

  const navLinks = forcedNavOrder
    .reduce((acc, cur) => {
      // return records based on the defined order
      return acc.concat(navNodes[cur]);
    }, [])
    .concat(navNodes.items)
    .map((slug) => {
      if (slug) {
        // find MDX-node by slug
        const { node } = edges.find(({ node }) => node.fields.slug === slug);
        // return title & slug as navigation path
        return { title: node.fields.title, url: node.fields.slug };
      }
    });
  console.log('Navigation Links:');
  console.log(navLinks);

  return navLinks;
};

// DEPRECATED
export const getNavNodesFromEdges = (edges, location) => {
  // get filtered edges
  const filteredAndSortedEdges = getFilteredAndSortedEdges(edges, location);

  console.log('PreviousNext Nodes:');
  //console.log(filteredAndSortedEdges);

  // extract navNodes from filtered edges
  const navNodesBase = filteredAndSortedEdges.map(({ node }) => node.fields.slug);
  const navNodesFiltered = navNodesBase.filter((slug) => slug !== '/');

  const navNodes = navNodesFiltered.reduce(
    (acc, cur) => {
      console.log('Reduce:');
      console.log(cur);

      if (forcedNavOrder.find((url) => url === cur)) {
        // add new NavigationNode
        return { ...acc, [cur]: [cur] };
      }

      let prefix = cur.split('/')[1];
      if (config.gatsby && config.gatsby.trailingSlash) {
        prefix = prefix + '/';
      }

      if (prefix && forcedNavOrder.find((url) => url === `/${prefix}`)) {
        // add new record to parent NavigationNode
        return { ...acc, [`/${prefix}`]: [...acc[`/${prefix}`], cur] };
      } else {
        // no navigation path available, assign record to Items array
        return { ...acc, items: [...acc.items, cur] };
      }
    },
    { items: [] }
  );
  // console.log('Navigation Nodes:');
  // console.log(navNodes);

  return navNodes;
};
