import { css } from '@emotion/react';

const customStyles = css`
  /* header height */
  .navBarDefault {
    height: 60px;
  }
  /* logo distance from DOCS string */
  .navBarBrand {
    padding-right: 10px;
  }
  /* adjust DOCS string related to the logo */
  .headerTitle {
    margin-top: 5px;
  }
  /* adjust sidebar */
  .sideBarUL {
    margin-top: 12px;
  }
  .sideBarUL li a {
    padding-left: 16px;
  }

  /* justify header components right */
  .topnav {
    flex-grow: 1;
  }
  .navBarNav {
    justify-content: end;
  }

  @media (max-width: 767px) {
    .navBarDefault {
      height: auto;
    }
    .navBarBrand {
      padding-right: 8px;
    }
  }
`;

export const styles = [customStyles];
