import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import config from '../../config/config';
import { Sidebar, ListItem } from './styles/Sidebar';

const SidebarLayout = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            fields {
              slug
            }
            tableOfContents
          }
        }
      }
    }
  `);

  let finalNavItems;

  if (data.allMdx.edges !== undefined && data.allMdx.edges.length > 0) {
    const navItems = data.allMdx.edges.map((item, index) => {
      let innerItems;

      if (item !== undefined) {
        // TODO: check auf Trailing Slashes (wenn unterschiedlich in slug & pathname ist sidebar leer)
        if (
          item.node.fields.slug === location.pathname ||
          config.gatsby.pathPrefix + item.node.fields.slug === location.pathname
        ) {
          if (item.node.tableOfContents.items) {
            innerItems = item.node.tableOfContents.items.map((innerItem, index) => {
              const itemId = innerItem.title
                ? innerItem.title.replace(/\s+/g, '').toLowerCase()
                : '#';

              return (
                <ListItem key={index} to={`#${itemId}`} level={1}>
                  {innerItem.title}
                </ListItem>
              );
            });
          }
        }
      }
      if (innerItems) {
        finalNavItems = innerItems;
      }
    });
  }

  if (finalNavItems && finalNavItems.length) {
    return (
      <Sidebar>
        <ul className={'rightSideBarUL'}>
          <li className={'rightSideTitle'}>CONTENTS</li>
          {finalNavItems}
        </ul>
      </Sidebar>
    );
  } else {
    return (
      <Sidebar>
        <ul></ul>
      </Sidebar>
    );
  }
};

export default SidebarLayout;
